import React from "react";
import { useNavigate } from "react-router-dom";
import { LogOut } from "lucide-react";

import "./DashboardPage.css";
import Calendar from "../../components/Calendar/Calendar";

export default function DashboardPage() {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("jwt");
    sessionStorage.clear();
    navigate("/auth");
  };

  return (
    <div className="dashboard">
      <header className="dashboard__header">
        <div className="dashboard__header-content">
          <h1 className="dashboard__title">Dashboard</h1>
          <button className="dashboard__logout-btn" onClick={handleLogout}>
            <LogOut className="dashboard__logout-icon" size={16} />
            Logout
          </button>
        </div>
      </header>
      <main className="dashboard__main">
        <div className="dashboard__content">
          <Calendar />
        </div>
      </main>
    </div>
  );
}
