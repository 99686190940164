import React, { useState, useEffect, useCallback } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { useGetEventsQuery } from "../../redux/services/calendarApi";
import "./Calendar.css";
import CalendarHeader from "./CalendarHeader/CalendarHeader";
import CalendarGrid from "./CalendarGrid/CalendarGrid";
import TimezoneSelector from "./TimezoneSelector/TimezoneSelector";
import EventList from "./EventList/EventList";

dayjs.extend(utc);
dayjs.extend(timezone);

const isValidTimezone = (tz: string): boolean => {
  try {
    dayjs.tz(dayjs(), tz);
    return true;
  } catch {
    return false;
  }
};

export default function Calendar() {
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [selectedDay, setSelectedDay] = useState<dayjs.Dayjs | null>(null);
  const [selectedTimezone, setSelectedTimezone] = useState(() => {
    const storedTimezone = localStorage.getItem("selectedTimezone");
    return storedTimezone && isValidTimezone(storedTimezone)
      ? storedTimezone
      : dayjs.tz.guess();
  });
  const [is24HourFormat, setIs24HourFormat] = useState(() => {
    const stored24HourFormat = localStorage.getItem("is24HourFormat");
    return stored24HourFormat ? JSON.parse(stored24HourFormat) : true;
  });

  const token = localStorage.getItem("jwt") || "";
  const startOfMonth = currentDate.startOf("month").toISOString();
  const endOfMonth = currentDate.endOf("month").toISOString();

  const { data: events = [] } = useGetEventsQuery({
    startDate: startOfMonth,
    endDate: endOfMonth,
    jwtToken: token,
  });

  const getEventDays = useCallback(() => {
    const now = dayjs().tz(selectedTimezone);
    return events
      .filter((event: any) => {
        const eventStart = dayjs(`${event.start.dateTime}Z`).tz(
          selectedTimezone
        );
        return eventStart.isAfter(now);
      })
      .map((event: any) =>
        dayjs(`${event.start.dateTime}Z`).tz(selectedTimezone).startOf("day")
      );
  }, [events, selectedTimezone]);

  const getUniqueEventDays = useCallback(() => {
    const eventDays = getEventDays();
    return Array.from(
      new Set(eventDays.map((d) => d.format("YYYY-MM-DD")))
    ).map((d) => dayjs(d));
  }, [getEventDays]);

  useEffect(() => {
    localStorage.setItem("selectedTimezone", selectedTimezone);
  }, [selectedTimezone]);

  useEffect(() => {
    localStorage.setItem("is24HourFormat", JSON.stringify(is24HourFormat));
  }, [is24HourFormat]);

  const handlePreviousMonth = () => {
    setCurrentDate(currentDate.subtract(1, "month"));
  };

  const handleNextMonth = () => setCurrentDate(currentDate.add(1, "month"));

  const handleDayClick = (day: dayjs.Dayjs) => {
    const uniqueEventDays = getUniqueEventDays();
    if (uniqueEventDays.some((d) => d.isSame(day, "day"))) {
      setSelectedDay(day);
    }
  };

  return (
    <div className={selectedDay ? "calendar" : "calendar__no-selected-date"}>
      <h1 className="calendar__title">Select a Date & Time</h1>
      <div className="calendar__content">
        <div
          className={
            selectedDay ? "calendar__main" : "calendar__main--no-selected-date"
          }
        >
          <CalendarHeader
            currentDate={currentDate}
            onPreviousMonth={handlePreviousMonth}
            onNextMonth={handleNextMonth}
          />
          <CalendarGrid
            currentDate={currentDate}
            selectedDay={selectedDay}
            selectedTimezone={selectedTimezone}
            getUniqueEventDays={getUniqueEventDays}
            onDayClick={handleDayClick}
          />
          <TimezoneSelector
            selectedTimezone={selectedTimezone}
            setSelectedTimezone={setSelectedTimezone}
            is24HourFormat={is24HourFormat}
            setIs24HourFormat={setIs24HourFormat}
          />
        </div>
        {selectedDay && (
          <EventList
            selectedDay={selectedDay}
            events={events}
            selectedTimezone={selectedTimezone}
            is24HourFormat={is24HourFormat}
            currentDate={currentDate}
          />
        )}
      </div>
    </div>
  );
}
