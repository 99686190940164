import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useJwt } from "react-jwt";

import { refreshAccessToken } from "../../utils/helpers/refresh";
import { patchMSToken } from "../../utils/api/auth";

interface TokenManagerProps {
  children: React.ReactNode;
}

const TokenManager: React.FC<TokenManagerProps> = ({ children }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("jwt") || "";
  const { decodedToken } = useJwt<any>(token);

  const checkAndUpdateToken = async () => {
    if (!token) {
      localStorage.removeItem("jwt");
      navigate("/auth");
      return;
    }

    if (decodedToken && new Date() > new Date(decodedToken.expiresAt)) {
      try {
        const accData = await refreshAccessToken(
          decodedToken.token,
          decodedToken.sub
        );

        if (accData) {
          const newJwt = await patchMSToken(accData, token);
          localStorage.setItem("jwt", newJwt);
        } else {
          localStorage.removeItem("jwt");
          navigate("/auth");
        }
      } catch (error) {
        console.error("Error refreshing token:", error);
        localStorage.removeItem("jwt");
        navigate("/auth");
      }
    }
  };

  useEffect(() => {
    checkAndUpdateToken();
    // eslint-disable-next-line
  }, [decodedToken, navigate]);

  return <>{children}</>;
};

export default TokenManager;
