import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface CalendarEvent {
  id: string;
  subject: string;
  bodyPreview?: string;
  start: {
    dateTime: string;
  };
  end: {
    dateTime: string;
  };
}

// interface GetEventsResponse {
//   value: CalendarEvent[];
// }

interface GetEventsParams {
  startDate: string;
  endDate: string;
  jwtToken: string;
}

export const calendarApi = createApi({
  reducerPath: "calendarApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("jwt");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getEvents: builder.query<CalendarEvent[], GetEventsParams>({
      query: ({ startDate, endDate, jwtToken }) => ({
        url: "calendar",
        method: "GET",
        params: { startDate, endDate },
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      }),
    }),
  }),
});

export const { useGetEventsQuery } = calendarApi;
