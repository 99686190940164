import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import AuthPage from "./pages/AuthPage/AuthPage";
import PublicRoute from "./routes/PublicRoute";
import PrivateRoute from "./routes/PrivateRoute";
import TokenManager from "./components/TokenManager/TokenManager";
import DashboardPage from "./pages/DashboardPage/DashboardPage";

const isAuthenticated = () => !!localStorage.getItem("jwt");

const App: React.FC = () => {
  return (
    <Router>
      <TokenManager>
        <Routes>
          <Route
            path="/auth"
            element={
              <PublicRoute>
                <AuthPage />
              </PublicRoute>
            }
          />

          <Route
            path="/calendar"
            element={
              <PrivateRoute>
                <DashboardPage />
              </PrivateRoute>
            }
          />

          <Route
            path="/"
            element={
              isAuthenticated() ? (
                <Navigate to="/calendar" />
              ) : (
                <Navigate to="/auth" />
              )
            }
          />

          <Route
            path="*"
            element={
              <Navigate to={isAuthenticated() ? "/calendar" : "/auth"} />
            }
          />
        </Routes>
      </TokenManager>
    </Router>
  );
};

export default App;
